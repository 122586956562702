import React, { useState } from 'react';

import PropTypes from 'prop-types';

import smallArrow from '../../../assets/icons/smallArrow.svg';
import './Accordion.scss';

function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion_container">
      <button type="button" className="accordion_button" onClick={toggleAccordion}>
        <img src={smallArrow} alt="arrow" className={isOpen ? 'open' : ''} />
        <span>{title}</span>
      </button>
      {isOpen && <div className="accordion_content">{content}</div>}
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired
};

export default Accordion;
