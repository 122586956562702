import PropTypes from 'prop-types';

import FrameOne from '../../../../../assets/KnowMoreModal/FrameOne.svg';
import FrameTwo from '../../../../../assets/KnowMoreModal/FrameTwo.svg';
import Accordion from '../../../../Shared/Accordion/Accordion';
import Modal from '../../../../Shared/Modal';

function KnowMoreModal({ showModal, onHide }) {
  const accordionData = [
    {
      title: 'Why does the status change?',
      content: (
        <div id="know_more_accordion_content_div">
          <p>
            Interactions done by the user i.e. transaction ID is an ongoing process that can change
            the flags and status of their applications.{' '}
          </p>
        </div>
      )
    },
    {
      title: 'How does the flag change?',
      content: (
        <div id="know_more_accordion_content_div">
          <img src={FrameOne} alt="Explanation" style={{ maxWidth: '100%' }} />
          <p>
            In the image, you will notice that the user has attempted the Digilocker step twice.{' '}
          </p>
          <p>
            In the Digilocker 1st attempt, a flag was thrown, leading to the user&rsquo;s
            &rsquo;Auto declined&rsquo; status.
          </p>
          <p>
            In the Digilocker 2nd attempt, another flag was thrown, replacing the earlier
            attempt&rsquo;s flag.
          </p>
          <p>
            The flag from the latest attempt of the module alone is shown on the Application details
            page in the &rsquo;Flags from workflow&rsquo; section..
          </p>
        </div>
      )
    },
    {
      title: 'How does the status change?',
      content: (
        <div id="know_more_accordion_content_div">
          <img src={FrameTwo} alt="Explanation" style={{ maxWidth: '100%' }} />
          <p>
            In the image, you will notice that the Digilocker step has two attempts by the user.{' '}
          </p>
          <p>
            In the Digilocker 1st attempt, a flag was thrown giving the application an &apos;Auto
            declined&apos; status.
          </p>
          <p>
            In the Digilocker 2nd attempt, there were no flags and post a few more steps the status
            of the user was updated to &apos;Auto approved&apos;.
          </p>
        </div>
      )
    }
  ];

  return (
    <Modal className="know_more_modal" show={showModal} onHide={onHide}>
      <Modal.Header className="know_more_modal_header">
        <h5>FAQ</h5>
      </Modal.Header>
      <Modal.Body className="know_more_modal_body">
        {accordionData.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Accordion key={index} title={item.title} content={item.content} />
        ))}
      </Modal.Body>
    </Modal>
  );
}

KnowMoreModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default KnowMoreModal;
